<template>
  <ion-page>
    <ion-header mode="md">
      <ion-toolbar> </ion-toolbar>
    </ion-header>
    <ion-segment
      mode="md"
      color="tertiary"
      value="1"
      @ionChange="changeType($event)"
    >
      <ion-segment-button mode="md" value="1" selected>
        <ion-label>Commenti</ion-label>
      </ion-segment-button>
      <ion-segment-button mode="md" value="2">
        <ion-label>Schede</ion-label>
      </ion-segment-button>
      <ion-segment-button mode="md" value="3">
        <ion-label>Sintesi</ion-label>
      </ion-segment-button>
    </ion-segment>
    <ion-content>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="secondary" @click="openSearchParams()">
          <ion-icon name="search" />
        </ion-fab-button>
      </ion-fab>
      <ion-loading
        :is-open="loading"
        cssClass="loading"
        message="Caricamento..."
        :duration="timeout"
      />

      <ion-list>
        <div v-for="d in docs" :key="d" lines="none">
          <jn-doc :doc="d" :showAbstract="true" />
        </div>
      </ion-list>

      <ion-infinite-scroll
        @ionInfinite="loadDocuments($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Caricamento..."
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { SearchData } from '@/models/search.model';
import {
  CategoriesJuranet,
  CategoriesPrassiJuranet,
  CategoriesGiurisprudenza,
  CategoriesNormativa,
  OrderingTypes,
} from '@/models/enums.model';
import * as eventBus from '../../services/event.bus.js';
import JnDoc from '../../components/DocCard.vue';
import { Document } from '@/models/document.model';
import { EsService } from '@/services/es.service';
import { document, home, chevronBack } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { Categories, SearchTypes } from '@/models/enums.model';
import { useRouter, useRoute } from 'vue-router';
import JnAreaTematicaSearchParams from '../search/AreaTematicaSearchParams.vue';

addIcons({
  document: document,
  home: home,
  'chevron-back': chevronBack,
});

import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonList,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonLoading,
  IonIcon,
  IonFab,
  IonFabButton,
  modalController,
} from '@ionic/vue';
import { JnService } from '@/services/jn.service';

export default defineComponent({
  name: 'JnAreaTematica',
  components: {
    IonPage,
    IonList,
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    IonContent,
    IonHeader,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    JnDoc,
    IonLoading,
    IonIcon,
    IonFab,
    IonFabButton,
  },

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isDisabled = ref(false);
    const docs = ref([] as Document[]);
    const docsSize = ref();

    const loading = ref(true);
    const timeout = ref(5000);

    const testata = ref();

    const searchData = ref(
      new SearchData({
        index: process.env.VUE_APP_JURANEWS_INDEXES,
        from: 0,
        size: 100,
        idCategory: [Categories.COMMENTI],
        idAreaTematica: [route.params.id as string],
        withText: true,
        searchInMassime: false
      })
    );

    const pushDocuments = async () => {
      searchData.value.from = docs.value.length;
      const newDocs = await EsService.getDocs(searchData.value as SearchData);
      for (let i = 0; i < newDocs.length; i++) {
        docs.value.push(newDocs[i]);
      }
    };

    onMounted(async () => {
      loading.value = true;
      docs.value = await EsService.getDocs(searchData.value as SearchData);
      docsSize.value = await EsService.countDocs(
        searchData.value as SearchData
      );
      loading.value = false;
      eventBus.default.$on('reset-results', function(data: string) {
        docs.value = [];
        pushDocuments();
      });
      testata.value = JnService.getTestata(route.params.id as string);
    });

    const loadDocuments = async (ev: CustomEvent) => {
      setTimeout(() => {
        pushDocuments();
        const target = ev.target as HTMLIonInfiniteScrollElement;
        target.complete();
        if (docs.value.length == docsSize.value) {
          target.disabled = true;
        }
      }, 500);
    };

    pushDocuments();

    const openSearchParams = async () => {
      const searchDataParams = ref(
        new SearchData({
          index: process.env.VUE_APP_JURANEWS_INDEXES,
          from: 0,
          size: 10,
          idAreaTematica: [route.params.id as string],
          withText: true,
          searchType: SearchTypes.AREE_TEMATICHE,
          searchDescription: testata.value.name,
          searchInMassime: false
        })
      );

      const modal = await modalController.create({
        component: JnAreaTematicaSearchParams,
        componentProps: { searchData: searchDataParams.value },
        cssClass: 'searchModal',
      });

      modal.present();
      const result = await modal.onDidDismiss();
      if (result.data) {
        searchDataParams.value = result.data.value as SearchData;
        store.commit('searchData', searchDataParams.value);
        eventBus.default.$emit(
          'area-tematica-search-data',
          searchDataParams.value
        );
        router.push('/main/search');
      }
    };

    const changeType = async (ev: CustomEvent) => {
      loading.value = true;
      docs.value = [];
      searchData.value.from = 0;
      if (ev.detail.value == '1') {
        searchData.value.idCategory = [Categories.COMMENTI];
      } else if (ev.detail.value == '2') {
        searchData.value.idCategory = [Categories.SCHEDA_AUTORALE];
      } else if (ev.detail.value == '3') {
        searchData.value.idCategory = [Categories.SINTESI_GIURISPRUDENZIALI];
      }
      docs.value = await EsService.getDocs(searchData.value as SearchData);
      docsSize.value = await EsService.countDocs(
        searchData.value as SearchData
      );
      loading.value = false;
    };

    return {
      isDisabled,
      docs,
      loadDocuments,
      changeType,
      loading,
      timeout,
      testata,
      openSearchParams,
    };
  },
});
</script>
